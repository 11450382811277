<template>
  <div id="currnet-stock-list">
    <v-card>
      <v-card-title>現在在庫</v-card-title>
      <v-tabs v-model="Stocktabs">
        <v-tab
          href="#item"
          @click="loadItemStockCurrent"
        >
          単品
        </v-tab>
        <v-tab
          href="#set"
          @click="loadSetStockCurrent"
        >
          セット
        </v-tab>
        <v-tab
          href="#subscription"
          @click="loadSubsStockCurrent"
        >
          定期便
        </v-tab>

        <v-tabs-items
          v-model="Stocktabs"
          touchless
        >
          <v-tab-item value="item">
            <item-stock-current
              ref="itemStockCurrent"
              @has-mounted="getItemStockCurrent"
            ></item-stock-current>
          </v-tab-item>
          <v-tab-item value="set">
            <set-stock-current
              ref="setStockCurrent"
              @has-mounted="getSetStockCurrent"
            ></set-stock-current>
          </v-tab-item>
          <v-tab-item value="subscription">
            <subs-stock-current
              ref="subsStockCurrent"
              @has-mounted="getSubsStockCurrent"
            ></subs-stock-current>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import {
  mapMutations,
} from 'vuex'
import ItemStockCurrent from './ItemStockCurrent.vue'
import SetStockCurrent from './SetStockCurrent.vue'
import SubsStockCurrent from './SubsStockCurrent.vue'

export default {
  components: {
    ItemStockCurrent,
    SetStockCurrent,
    SubsStockCurrent,
  },
  data: () => ({
    Stocktabs: 'subscription',
    ItemFlag: false,
    SetFlag: false,
    SubscriptionFlag: false,
    currentMonth: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 7)
      .replace('-', ''),
  }),

  computed: {
  },

  watch: {
    Stocktabs(newValue) {
      this.$router.push({
        query: {
          selTab: newValue,
        },
      })
    },
  },

  mounted() {
    if (this.$route.query.selTab) {
      this.Stocktabs = this.$route.query.selTab
    }
    this.loadSelTab()
  },

  methods: {
    ...mapMutations('monthlyStockStore', ['clearItemMonthlyStock']),
    ...mapMutations('app', ['setOverlayStatus']),
    sleep(t) {
      const a = new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, t)
      })

      return a
    },
    clearCache() {
      this.clearItemMonthlyStock()

      // this.clearStocked()
    },
    loadSelTab() {
      if (this.Stocktabs === 'item') {
        this.loadItemStockCurrent()
      }
      if (this.Stocktabs === 'set') {
        this.loadSetStockCurrent()
      }
      if (this.Stocktabs === 'subscription') {
        this.loadSubsStockCurrent()
      }
    },
    getItemStockCurrent() {
      this.ItemFlag = true
    },
    getSetStockCurrent() {
      this.SetFlag = true
    },
    getSubsStockCurrent() {
      this.SubscriptionFlag = true
    },
    async loadItemStockCurrent() {
      this.clearCache()
      this.setOverlayStatus(true)
      while (!this.ItemFlag) {
        /* eslint-disable-next-line */
        await this.sleep(1000)
      }

      this.$refs.itemStockCurrent.loadData(this.currentMonth)
    },
    async loadSetStockCurrent() {
      this.clearCache()
      this.setOverlayStatus(true)
      while (!this.SetFlag) {
        /* eslint-disable-next-line */
        await this.sleep(1000)
      }

      this.$refs.setStockCurrent.loadData(this.currentMonth)
    },
    async loadSubsStockCurrent() {
      this.clearCache()
      this.setOverlayStatus(true)
      while (!this.SubscriptionFlag) {
        /* eslint-disable-next-line */
        await this.sleep(1000)
      }

      this.$refs.subsStockCurrent.loadData(this.currentMonth)
    },
  },
}
</script>
