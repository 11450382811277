<template>
  <v-row
    class="px-2 mt-2"
    align="center"
  >
    <v-col
      cols="12"
      md="3"
      class="py-4"
    >
      <v-autocomplete
        v-model="subs_code"
        :items="subsCodes"
        placeholder="定期便コード"
        outlined
        clearable
        dense
        hide-details
      ></v-autocomplete>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="12"
      md="3"
      style="text-align:right"
    >
      <v-btn
        color="secondary"
        outlined
        :loading="exportStatus"
        @click="downloadCSV"
      >
        <v-icon
          size="17"
          class="me-1"
        >
          {{ icon.mdiExportVariant }}
        </v-icon>
        <span>Export</span>
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      class="pa-0"
    >
      <v-data-table
        ref="MthlySubsStock"
        :headers="headers"
        :items="SubsCurrentlyStock"
        item-key="item_code"
        :items-per-page="-1"
      >
        <template v-slot:[`item.item_code`]="{ item }">
          <a
            href="javascript:void(0);"
            @click="pushItemDaily(item)"
          >{{ item.item_code }}</a>
        </template>
        <template v-slot:[`item.sum_total`]="{ item }">
          <label :style="item.sum_total < 0 ? 'color:#F44336' : ''">{{ item.sum_total ? item.sum_total.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0' }}</label>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiExportVariant,
} from '@mdi/js'
import {
  mapState, mapMutations, mapActions,
} from 'vuex'

// import agent from '@/api/agent'

export default {
  data: () => ({
    dialogDelete: false,
    icon: {
      mdiExportVariant,
    },
    subs_code: '',
    exportStatus: false,
    currentMonth: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 7)
      .replace('-', ''),
    headers: [
      {
        text: '単品コード',
        align: 'left',
        value: 'item_code',
        width: '10%',
        fixed: true,
      },
      {
        text: '商品名',
        align: 'left',
        value: 'item_name',
        width: '70%',
        fixed: true,
      },
      {
        text: '現在在庫数量',
        value: 'sum_total',
        align: 'right',
        width: '20%',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('currentlyStockStore', ['SubsCurrentlyStock']),
    ...mapState('subscriptionStore', ['subsCodes']),
  },

  watch: {
    subs_code(newValue) {
      this.loadData(this.currentMonth, newValue)
    },
  },

  mounted() {
    this.$emit('has-mounted')
  },

  methods: {
    ...mapActions('currentlyStockStore', ['loadSubsCurrentlyStock']),
    ...mapActions('subscriptionStore', ['loadSubscriptionCodes']),
    ...mapMutations('app', ['setOverlayStatus']),
    pushItemDaily(item) {
      console.log('pushItem:', this.$route.params.month, item.item_code)
      const today = new Date()
      const yyyymm = (`${today.getFullYear().toString()}0${today.getMonth() + 1}`)

      console.log('currentyyyymm', yyyymm)
      this.$router.push({
        name: 'stock-item-daily',
        params: {
          month: yyyymm,
        },
        query: {
          selTab: this.$route.query.selTab,
          item_code: item.item_code,
          from: 'current',
        },

        // query: { originalMth: this.$route.params.month, selTab: 'product' },
      })
    },
    loadData(selMonth, selSubsCode) {
      this.setOverlayStatus(true)
      this.loadSubscriptionCodes()
      this.loadSubsCurrentlyStock({
        type: 'subscription', month: selMonth, subs_code: selSubsCode,
      }).then(() => {
        this.setOverlayStatus(false)
      })
    },
    downloadCSV() {
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
